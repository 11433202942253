// source: profileservice/skill.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.profileservice.DeleteSkillReply', null, global);
goog.exportSymbol('proto.profileservice.DeleteSkillRequest', null, global);
goog.exportSymbol('proto.profileservice.GetSkillsReply', null, global);
goog.exportSymbol('proto.profileservice.GetSkillsRequest', null, global);
goog.exportSymbol('proto.profileservice.SearchSkillsRequest', null, global);
goog.exportSymbol('proto.profileservice.Skill', null, global);
goog.exportSymbol('proto.profileservice.SkillDataType', null, global);
goog.exportSymbol('proto.profileservice.SkillKind', null, global);
goog.exportSymbol('proto.profileservice.SkillReply', null, global);
goog.exportSymbol('proto.profileservice.SkillsReply', null, global);
goog.exportSymbol('proto.profileservice.UpdateSkill', null, global);
goog.exportSymbol('proto.profileservice.UpdateSkillRequest', null, global);
goog.exportSymbol('proto.profileservice.UpdateSkillsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.Skill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.Skill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.Skill.displayName = 'proto.profileservice.Skill';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.UpdateSkill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.UpdateSkill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.UpdateSkill.displayName = 'proto.profileservice.UpdateSkill';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.GetSkillsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.GetSkillsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.GetSkillsRequest.displayName = 'proto.profileservice.GetSkillsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.SearchSkillsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.SearchSkillsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.SearchSkillsRequest.displayName = 'proto.profileservice.SearchSkillsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.UpdateSkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.UpdateSkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.UpdateSkillRequest.displayName = 'proto.profileservice.UpdateSkillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.UpdateSkillsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.UpdateSkillsRequest.repeatedFields_, null);
};
goog.inherits(proto.profileservice.UpdateSkillsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.UpdateSkillsRequest.displayName = 'proto.profileservice.UpdateSkillsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.GetSkillsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.GetSkillsReply.repeatedFields_, null);
};
goog.inherits(proto.profileservice.GetSkillsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.GetSkillsReply.displayName = 'proto.profileservice.GetSkillsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.SkillsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.SkillsReply.repeatedFields_, null);
};
goog.inherits(proto.profileservice.SkillsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.SkillsReply.displayName = 'proto.profileservice.SkillsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.SkillReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.SkillReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.SkillReply.displayName = 'proto.profileservice.SkillReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.DeleteSkillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.DeleteSkillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.DeleteSkillRequest.displayName = 'proto.profileservice.DeleteSkillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.DeleteSkillReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.DeleteSkillReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.DeleteSkillReply.displayName = 'proto.profileservice.DeleteSkillReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.Skill.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.Skill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.Skill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.Skill.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    yearsOfExperience: jspb.Message.getFieldWithDefault(msg, 4, 0),
    level: jspb.Message.getFieldWithDefault(msg, 5, 0),
    levelName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    skillId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    kind: jspb.Message.getFieldWithDefault(msg, 11, 0),
    skillCategoryId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.Skill}
 */
proto.profileservice.Skill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.Skill;
  return proto.profileservice.Skill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.Skill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.Skill}
 */
proto.profileservice.Skill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfileId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearsOfExperience(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevelName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkillId(value);
      break;
    case 11:
      var value = /** @type {!proto.profileservice.SkillKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkillCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.Skill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.Skill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.Skill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.Skill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfileId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getYearsOfExperience();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLevelName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSkillId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSkillCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 profile_id = 2;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.profileservice.Skill.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 years_of_experience = 4;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getYearsOfExperience = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setYearsOfExperience = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 level = 5;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string level_name = 6;
 * @return {string}
 */
proto.profileservice.Skill.prototype.getLevelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setLevelName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.profileservice.Skill.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 skill_id = 10;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getSkillId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional SkillKind kind = 11;
 * @return {!proto.profileservice.SkillKind}
 */
proto.profileservice.Skill.prototype.getKind = function() {
  return /** @type {!proto.profileservice.SkillKind} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.profileservice.SkillKind} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int32 skill_category_id = 12;
 * @return {number}
 */
proto.profileservice.Skill.prototype.getSkillCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Skill} returns this
 */
proto.profileservice.Skill.prototype.setSkillCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.UpdateSkill.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.UpdateSkill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.UpdateSkill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateSkill.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    yearsOfExperience: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    skillId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.UpdateSkill}
 */
proto.profileservice.UpdateSkill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.UpdateSkill;
  return proto.profileservice.UpdateSkill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.UpdateSkill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.UpdateSkill}
 */
proto.profileservice.UpdateSkill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearsOfExperience(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkillId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.UpdateSkill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.UpdateSkill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.UpdateSkill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateSkill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYearsOfExperience();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSkillId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.UpdateSkill.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkill} returns this
 */
proto.profileservice.UpdateSkill.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.profileservice.UpdateSkill.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdateSkill} returns this
 */
proto.profileservice.UpdateSkill.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 years_of_experience = 3;
 * @return {number}
 */
proto.profileservice.UpdateSkill.prototype.getYearsOfExperience = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkill} returns this
 */
proto.profileservice.UpdateSkill.prototype.setYearsOfExperience = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.profileservice.UpdateSkill.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdateSkill} returns this
 */
proto.profileservice.UpdateSkill.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 skill_id = 5;
 * @return {number}
 */
proto.profileservice.UpdateSkill.prototype.getSkillId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkill} returns this
 */
proto.profileservice.UpdateSkill.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.GetSkillsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.GetSkillsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.GetSkillsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetSkillsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dataType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.GetSkillsRequest}
 */
proto.profileservice.GetSkillsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.GetSkillsRequest;
  return proto.profileservice.GetSkillsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.GetSkillsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.GetSkillsRequest}
 */
proto.profileservice.GetSkillsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!proto.profileservice.SkillKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = /** @type {!proto.profileservice.SkillDataType} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.GetSkillsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.GetSkillsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.GetSkillsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetSkillsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.profileservice.GetSkillsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetSkillsRequest} returns this
 */
proto.profileservice.GetSkillsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SkillKind kind = 2;
 * @return {!proto.profileservice.SkillKind}
 */
proto.profileservice.GetSkillsRequest.prototype.getKind = function() {
  return /** @type {!proto.profileservice.SkillKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.profileservice.SkillKind} value
 * @return {!proto.profileservice.GetSkillsRequest} returns this
 */
proto.profileservice.GetSkillsRequest.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SkillDataType data_type = 3;
 * @return {!proto.profileservice.SkillDataType}
 */
proto.profileservice.GetSkillsRequest.prototype.getDataType = function() {
  return /** @type {!proto.profileservice.SkillDataType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.profileservice.SkillDataType} value
 * @return {!proto.profileservice.GetSkillsRequest} returns this
 */
proto.profileservice.GetSkillsRequest.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.SearchSkillsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.SearchSkillsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.SearchSkillsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.SearchSkillsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    per: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.SearchSkillsRequest}
 */
proto.profileservice.SearchSkillsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.SearchSkillsRequest;
  return proto.profileservice.SearchSkillsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.SearchSkillsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.SearchSkillsRequest}
 */
proto.profileservice.SearchSkillsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.SearchSkillsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.SearchSkillsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.SearchSkillsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.SearchSkillsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPer();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 from_id = 1;
 * @return {number}
 */
proto.profileservice.SearchSkillsRequest.prototype.getFromId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.SearchSkillsRequest} returns this
 */
proto.profileservice.SearchSkillsRequest.prototype.setFromId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per = 2;
 * @return {number}
 */
proto.profileservice.SearchSkillsRequest.prototype.getPer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.SearchSkillsRequest} returns this
 */
proto.profileservice.SearchSkillsRequest.prototype.setPer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.UpdateSkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.UpdateSkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.UpdateSkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateSkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    yearsOfExperience: jspb.Message.getFieldWithDefault(msg, 4, 0),
    level: jspb.Message.getFieldWithDefault(msg, 5, 0),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    skillId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.UpdateSkillRequest}
 */
proto.profileservice.UpdateSkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.UpdateSkillRequest;
  return proto.profileservice.UpdateSkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.UpdateSkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.UpdateSkillRequest}
 */
proto.profileservice.UpdateSkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearsOfExperience(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkillId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.UpdateSkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.UpdateSkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.UpdateSkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateSkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getYearsOfExperience();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSkillId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.UpdateSkillRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkillRequest} returns this
 */
proto.profileservice.UpdateSkillRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.profileservice.UpdateSkillRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkillRequest} returns this
 */
proto.profileservice.UpdateSkillRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.profileservice.UpdateSkillRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdateSkillRequest} returns this
 */
proto.profileservice.UpdateSkillRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 years_of_experience = 4;
 * @return {number}
 */
proto.profileservice.UpdateSkillRequest.prototype.getYearsOfExperience = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkillRequest} returns this
 */
proto.profileservice.UpdateSkillRequest.prototype.setYearsOfExperience = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 level = 5;
 * @return {number}
 */
proto.profileservice.UpdateSkillRequest.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkillRequest} returns this
 */
proto.profileservice.UpdateSkillRequest.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.profileservice.UpdateSkillRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdateSkillRequest} returns this
 */
proto.profileservice.UpdateSkillRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 skill_id = 7;
 * @return {number}
 */
proto.profileservice.UpdateSkillRequest.prototype.getSkillId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkillRequest} returns this
 */
proto.profileservice.UpdateSkillRequest.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.UpdateSkillsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.UpdateSkillsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.UpdateSkillsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.UpdateSkillsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateSkillsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updateSkillsList: jspb.Message.toObjectList(msg.getUpdateSkillsList(),
    proto.profileservice.UpdateSkill.toObject, includeInstance),
    kind: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.UpdateSkillsRequest}
 */
proto.profileservice.UpdateSkillsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.UpdateSkillsRequest;
  return proto.profileservice.UpdateSkillsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.UpdateSkillsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.UpdateSkillsRequest}
 */
proto.profileservice.UpdateSkillsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.profileservice.UpdateSkill;
      reader.readMessage(value,proto.profileservice.UpdateSkill.deserializeBinaryFromReader);
      msg.addUpdateSkills(value);
      break;
    case 3:
      var value = /** @type {!proto.profileservice.SkillKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.UpdateSkillsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.UpdateSkillsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.UpdateSkillsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateSkillsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUpdateSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.profileservice.UpdateSkill.serializeBinaryToWriter
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.profileservice.UpdateSkillsRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateSkillsRequest} returns this
 */
proto.profileservice.UpdateSkillsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated UpdateSkill update_skills = 2;
 * @return {!Array<!proto.profileservice.UpdateSkill>}
 */
proto.profileservice.UpdateSkillsRequest.prototype.getUpdateSkillsList = function() {
  return /** @type{!Array<!proto.profileservice.UpdateSkill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profileservice.UpdateSkill, 2));
};


/**
 * @param {!Array<!proto.profileservice.UpdateSkill>} value
 * @return {!proto.profileservice.UpdateSkillsRequest} returns this
*/
proto.profileservice.UpdateSkillsRequest.prototype.setUpdateSkillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.profileservice.UpdateSkill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profileservice.UpdateSkill}
 */
proto.profileservice.UpdateSkillsRequest.prototype.addUpdateSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.profileservice.UpdateSkill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.UpdateSkillsRequest} returns this
 */
proto.profileservice.UpdateSkillsRequest.prototype.clearUpdateSkillsList = function() {
  return this.setUpdateSkillsList([]);
};


/**
 * optional SkillKind kind = 3;
 * @return {!proto.profileservice.SkillKind}
 */
proto.profileservice.UpdateSkillsRequest.prototype.getKind = function() {
  return /** @type {!proto.profileservice.SkillKind} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.profileservice.SkillKind} value
 * @return {!proto.profileservice.UpdateSkillsRequest} returns this
 */
proto.profileservice.UpdateSkillsRequest.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.GetSkillsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.GetSkillsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.GetSkillsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.GetSkillsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetSkillsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillsList: jspb.Message.toObjectList(msg.getSkillsList(),
    proto.profileservice.Skill.toObject, includeInstance),
    translatedCharacterCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.GetSkillsReply}
 */
proto.profileservice.GetSkillsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.GetSkillsReply;
  return proto.profileservice.GetSkillsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.GetSkillsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.GetSkillsReply}
 */
proto.profileservice.GetSkillsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profileservice.Skill;
      reader.readMessage(value,proto.profileservice.Skill.deserializeBinaryFromReader);
      msg.addSkills(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTranslatedCharacterCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.GetSkillsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.GetSkillsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.GetSkillsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetSkillsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.profileservice.Skill.serializeBinaryToWriter
    );
  }
  f = message.getTranslatedCharacterCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Skill skills = 1;
 * @return {!Array<!proto.profileservice.Skill>}
 */
proto.profileservice.GetSkillsReply.prototype.getSkillsList = function() {
  return /** @type{!Array<!proto.profileservice.Skill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profileservice.Skill, 1));
};


/**
 * @param {!Array<!proto.profileservice.Skill>} value
 * @return {!proto.profileservice.GetSkillsReply} returns this
*/
proto.profileservice.GetSkillsReply.prototype.setSkillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.profileservice.Skill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profileservice.Skill}
 */
proto.profileservice.GetSkillsReply.prototype.addSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.profileservice.Skill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.GetSkillsReply} returns this
 */
proto.profileservice.GetSkillsReply.prototype.clearSkillsList = function() {
  return this.setSkillsList([]);
};


/**
 * optional int32 translated_character_count = 2;
 * @return {number}
 */
proto.profileservice.GetSkillsReply.prototype.getTranslatedCharacterCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetSkillsReply} returns this
 */
proto.profileservice.GetSkillsReply.prototype.setTranslatedCharacterCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.SkillsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.SkillsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.SkillsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.SkillsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.SkillsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillsList: jspb.Message.toObjectList(msg.getSkillsList(),
    proto.profileservice.Skill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.SkillsReply}
 */
proto.profileservice.SkillsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.SkillsReply;
  return proto.profileservice.SkillsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.SkillsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.SkillsReply}
 */
proto.profileservice.SkillsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profileservice.Skill;
      reader.readMessage(value,proto.profileservice.Skill.deserializeBinaryFromReader);
      msg.addSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.SkillsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.SkillsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.SkillsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.SkillsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.profileservice.Skill.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Skill skills = 1;
 * @return {!Array<!proto.profileservice.Skill>}
 */
proto.profileservice.SkillsReply.prototype.getSkillsList = function() {
  return /** @type{!Array<!proto.profileservice.Skill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profileservice.Skill, 1));
};


/**
 * @param {!Array<!proto.profileservice.Skill>} value
 * @return {!proto.profileservice.SkillsReply} returns this
*/
proto.profileservice.SkillsReply.prototype.setSkillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.profileservice.Skill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profileservice.Skill}
 */
proto.profileservice.SkillsReply.prototype.addSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.profileservice.Skill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.SkillsReply} returns this
 */
proto.profileservice.SkillsReply.prototype.clearSkillsList = function() {
  return this.setSkillsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.SkillReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.SkillReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.SkillReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.SkillReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    skill: (f = msg.getSkill()) && proto.profileservice.Skill.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.SkillReply}
 */
proto.profileservice.SkillReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.SkillReply;
  return proto.profileservice.SkillReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.SkillReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.SkillReply}
 */
proto.profileservice.SkillReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profileservice.Skill;
      reader.readMessage(value,proto.profileservice.Skill.deserializeBinaryFromReader);
      msg.setSkill(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.SkillReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.SkillReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.SkillReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.SkillReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkill();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.profileservice.Skill.serializeBinaryToWriter
    );
  }
};


/**
 * optional Skill skill = 1;
 * @return {?proto.profileservice.Skill}
 */
proto.profileservice.SkillReply.prototype.getSkill = function() {
  return /** @type{?proto.profileservice.Skill} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.Skill, 1));
};


/**
 * @param {?proto.profileservice.Skill|undefined} value
 * @return {!proto.profileservice.SkillReply} returns this
*/
proto.profileservice.SkillReply.prototype.setSkill = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.SkillReply} returns this
 */
proto.profileservice.SkillReply.prototype.clearSkill = function() {
  return this.setSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.SkillReply.prototype.hasSkill = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.DeleteSkillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.DeleteSkillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.DeleteSkillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeleteSkillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.DeleteSkillRequest}
 */
proto.profileservice.DeleteSkillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.DeleteSkillRequest;
  return proto.profileservice.DeleteSkillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.DeleteSkillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.DeleteSkillRequest}
 */
proto.profileservice.DeleteSkillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.DeleteSkillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.DeleteSkillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.DeleteSkillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeleteSkillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.DeleteSkillRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.DeleteSkillRequest} returns this
 */
proto.profileservice.DeleteSkillRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.profileservice.DeleteSkillRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.DeleteSkillRequest} returns this
 */
proto.profileservice.DeleteSkillRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.DeleteSkillReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.DeleteSkillReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.DeleteSkillReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeleteSkillReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.DeleteSkillReply}
 */
proto.profileservice.DeleteSkillReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.DeleteSkillReply;
  return proto.profileservice.DeleteSkillReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.DeleteSkillReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.DeleteSkillReply}
 */
proto.profileservice.DeleteSkillReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.DeleteSkillReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.DeleteSkillReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.DeleteSkillReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeleteSkillReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.profileservice.SkillKind = {
  SKILL_KIND_UNSPECIFIED: 0,
  SKILL_KIND_TOOL_PROGRAMMING: 1,
  SKILL_KIND_BUSINESS_CREATIVE_TOOL: 2,
  SKILL_KIND_OTHER: 99
};

/**
 * @enum {number}
 */
proto.profileservice.SkillDataType = {
  SKILL_DATA_TYPE_ALL: 0,
  SKILL_DATA_TYPE_V1: 1,
  SKILL_DATA_TYPE_V2: 2
};

goog.object.extend(exports, proto.profileservice);
